.contenedor_img {
  display: flex;
  height: 100%;
  align-items: center;
}

.contenedor_img_nota .from_right {
  justify-content: inherit;
}

.contenedor_img_nota img {
  height: 245px;
}

.img_js {
  margin: 2rem 0;
  border-radius: 10px;
}

.articulo {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  font-size: 25px;
}

.articulo ul {
  margin-left: 2rem;
}

.articulo p {
  margin-bottom: 1rem;
}

.card {
  display: flex;
  background: linear-gradient(
    to left top,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.5)
  );
  border-radius: 1rem;
  margin: 1rem 0rem;
  padding: 20px;
  box-shadow: 6px 6px 20px rgba(122, 122, 122, 0.212);
  justify-content: flex-start;
  width: 100%;
  border-style: none;
}

.card_info {
  width: 100%;
}

.card_info p {
  font-size: 20px;
  font-weight: 500;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion:hover {
  background: linear-gradient(
    to left top,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.5)
  );
}

/* Style button */

.accordion img {
  height: 125px;
  border-radius: 25px;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 20px;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  border-radius: var(--button-radius);
}
/* Form */
.form__title {
  font-weight: 300;
  margin: 0;
  margin-bottom: 1.25rem;
}

.container {
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  border-radius: var(--button-radius);
  box-shadow: 0 0.5rem 1.1rem rgb(0 0 0 / 25%), 0 0.1rem 0.1rem rgb(0 0 0 / 0%);
  overflow: hidden;
  position: relative;
  margin: 0.5rem 0;
}

.container__form {
  height: 55vh;
}

.container__form p input {
  width: 75%;
}

.btn {
  background-color: var(--blue);
  background-image: linear-gradient(
    90deg,
    var(--blue) 0%,
    var(--lightblue) 74%
  );
  border-radius: 20px;
  border: 1px solid var(--blue);
  color: var(--white);
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding: 0px 4rem;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  max-height: 50px;
  margin: 0 10px;
}

.btn:active {
  transform: scale(0.95);
}

.btn:focus {
  outline: none;
}

.form {
  padding: 2rem 3rem;
  text-align: center;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(auto, 43vh);
  gap: 0px 3rem;
  font-size: 20px;
  position: relative;
}

.from_left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.from_right {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  width: 100%;
}

.content_resultado {
  width: 100%;
}

.resultado {
  display: grid;
  grid-template-columns: 1fr 2fr;
  background: #fff;
  margin-bottom: 1rem;
  padding: 10px;
}

.resultado_ecuacion {
  display: flex;
  background: #fff;
  margin-bottom: 1rem;
  padding: 10px;
}

.form p {
  margin: 0;
}

.input {
  background-color: #fff;
  border: none;
  padding: 0.7rem 0.9rem;
  margin: 10px 0;
  width: 100%;
  font-size: 16px;
  text-transform: uppercase;
}

.centar {
  text-transform: uppercase;
}

.table_responsive {
  min-height: 0.01%;
  overflow-x: auto;
  font-size: 16px;
}

.tabla {
  width: 100%;
  border: 1px solid #000;
  margin: 15px 0;
}
th,
td {
  width: 25%;
  text-align: left;
  vertical-align: top;
  border: 1px solid #000;
}

@media screen and (max-width: 767px) {
  .table_responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .table_responsive > .tabla {
    margin-bottom: 0;
  }
  .table_responsive > .tabla > thead > tr > th,
  .table_responsive > .tabla > tbody > tr > th,
  .table_responsive > .tabla > tfoot > tr > th,
  .table_responsive > .tabla > thead > tr > td,
  .table_responsive > .tabla > tbody > tr > td,
  .table_responsive > .tabla > tfoot > tr > td {
    white-space: nowrap;
  }
}

@media screen and (max-width: 320px) {
  .hide_on_mobile {
    display: none;
  }
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}
@media screen {
}

.flecha {
  position: absolute;
  left: 46%;
  top: 47%;
  height: 60px;
}

.form_Pascal {
  padding: 2rem 3rem;
  text-align: center;
  height: 100%;
}

.buscar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.speech {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin-top: 20px;
}

.speech input {
  font-size: 25px;
  color: #658ec6;
}

.speech img {
  margin-top: 20px;
  height: 100px;
  width: 100px;
  cursor: pointer;
}

.botones {
  display: flex;
  justify-content: center;
  grid-column: 1 / 3;
  grid-row: 2;
}

.centrar_pascal {
  text-align: center;
}

.apli {
  background: linear-gradient(to right top, #65dfc9, #6cdbeb);
  border-radius: 0.5rem;
  color: white;
  padding: 1rem;
  width: 75%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 15px;
}

.apli img {
  height: 100px;
}
.apli h2 {
  color: white;
  font-size: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1440px) {
  .apli h2 {
    font-size: 20px;
  }

  .apli img {
    height: 50px;
  }
}

.link {
  display: grid;
  padding: 8px 0px;
  align-items: center;
  grid-template-columns: 1fr 2fr;
}

.link img {
  height: 50px;
  margin: auto;
}

.link h2 {
  font-weight: bold;
  text-align: initial;
}

.customBtn {
  width: 70%;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
  text-decoration: none;
}

.boton,
.activo {
  line-height: 42px;
  padding: 0;
  border: none;
  margin-bottom: 20px;
}

.boton:before,
.boton:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 1px;
  box-shadow: -1px -1px 20px 0px rgba(255, 255, 255, 1),
    -4px -4px 5px 0px rgba(255, 255, 255, 1),
    7px 7px 20px 0px rgba(0, 0, 0, 0.4), 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
}

.boton:before {
  right: 0;
  top: 0;
  transition: all 500ms ease;
}
.boton:after {
  left: 0;
  bottom: 0;
  transition: all 500ms ease;
}

.boton:hover {
  background: transparent;
  color: #76aef1;
  box-shadow: none;
}

.boton:hover:before {
  transition: all 500ms ease;
  height: 100%;
}

.boton:hover:after {
  transition: all 500ms ease;
  height: 100%;
}

.activo {
  box-shadow: none;
}

.activo:before {
  top: 0;
  height: 100%;
  right: 0;
}
.activo:after {
  left: 0;
  bottom: 0;
  height: 100%;
}

.activo:before,
.activo:after {
  position: absolute;
  content: "";
  width: 1px;
  box-shadow: -1px -1px 20px 0px rgba(255, 255, 255, 1),
    -4px -4px 5px 0px rgba(255, 255, 255, 1),
    7px 7px 20px 0px rgba(0, 0, 0, 0.4), 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1440px) {
  .boton,
  .activo {
    line-height: 0px;
    padding: 5px 15px;
    border: none;
    margin-bottom: 15px;
  }

  .link {
    padding: 1px 0px;
  }

  .link h2 {
    font-size: 17px;
  }

  .link img {
    height: 30px;
  }
}
